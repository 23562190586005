.exclusive-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 20px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .exclusive-header {
    text-align: center;
    margin-bottom: 40px;
    padding-bottom: 20px;
  }
  
  .exclusive-image {
    width: 100%;
    max-height: 400px;
    object-fit: cover;
    margin-bottom: 20px;
  }
  
  .exclusive-content {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  
  .exclusive-item {
    text-align: center;
    padding: 20px;
    background-color: #f7f7f7;
    border-radius: 8px;
  }
  
  .learn-more-button, .shop-now-button, .watch-now-button {
    display: inline-block;
    padding: 12px 25px;
    background-color: #27ae60;
    color: white;
    text-decoration: none;
    font-family: "Roboto", sans-serif;
    border-radius: 25px;
    transition: background-color 0.3s ease;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  }
  
  .learn-more-button:hover, .shop-now-button:hover, .watch-now-button:hover {
    background-color: #2ecc71;
  }
  
  @media (max-width: 768px) {
    .exclusive-container {
      padding: 20px;
    }
  
    .exclusive-content {
      gap: 20px;
    }
  }
  