/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&family=Roboto:wght@300;400;500&display=swap');

/* CSS Variables for Consistent Styling */
:root {
    --primary-color: #27ae60;
    --secondary-color: #1abc9c;
    --accent-color: #f39c12;
    --light-bg: #f9f9f9;
    --dark-text: #2c3e50;
    --light-text: #7f8c8d;
    --font-primary: 'Montserrat', sans-serif;
    --font-secondary: 'Roboto', sans-serif;
}

/* Global Styles */
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    font-family: var(--font-secondary);
    background-color: #ffffff;
    color: var(--dark-text);
    line-height: 1.6;
}

a {
    color: var(--primary-color);
    text-decoration: none;
    transition: color 0.3s ease;
}

a:hover {
    color: var(--secondary-color);
}

.aco {
    color: white !important;
}

/* Container */
.container {
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 0;
}

/* Hero Section */
.hero {
    background-color: #4CAF50;
    color: #fff;
    padding: 100px 20px;
    text-align: center;
    position: relative;
}

.hero::after {
    content: '';
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 100%;
    height: 40px;
    background: #f9f9f9;
    border-top-left-radius: 50% 20px;
    border-top-right-radius: 50% 20px;
}

.hero-content {
    position: relative;
    z-index: 1;
    margin-top: 32px;
}

.hero-logo {
    width: 150px;
    margin-bottom: 20px;
}

.hero h1 {
    font-family: var(--font-primary);
    font-size: 3rem;
    margin-bottom: 10px;
}

.hero p {
    font-size: 1.2rem;
    margin-bottom: 30px;
}

.cta-button {
    display: inline-block;
    padding: 12px 30px;
    background-color: var(--accent-color);
    color: #fff;
    font-weight: 600;
    border-radius: 50px;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.cta-button:hover {
    background-color: var(--primary-color);
    transform: translateY(-3px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* About Us Section */
.about-us {
    background-color: var(--light-bg);
    padding: 60px 20px;
}

.about-us h2 {
    font-family: var(--font-primary);
    font-size: 2.5rem;
    color: var(--dark-text);
    text-align: center;
    margin-bottom: 20px;
}

.about-us p {
    font-size: 1.1rem;
    color: var(--light-text);
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
}

/* Services Section */
.services {
    padding: 60px 20px;
}

.services h2 {
    font-family: var(--font-primary);
    font-size: 2.5rem;
    color: var(--dark-text);
    text-align: center;
    margin-bottom: 40px;
}

.service-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 30px;
}

.card {
    background-color: #fff;
    padding: 30px;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
}

.card h3 {
    font-family: var(--font-primary);
    font-size: 1.5rem;
    color: var(--primary-color);
    margin-bottom: 15px;
}

.card p {
    font-size: 1rem;
    color: var(--light-text);
}

/* Consultations Section */
.consultations {
    background-color: var(--light-bg);
    padding: 60px 20px;
}

.consultations h2 {
    font-family: var(--font-primary);
    font-size: 2.5rem;
    color: var(--dark-text);
    text-align: center;
    margin-bottom: 40px;
}

.consultation-list {
    list-style: none;
    max-width: 800px;
    margin: 0 auto 30px auto;
    padding: 0;
}

.consultation-list li {
    background: #fff;
    padding: 20px;
    margin-bottom: 15px;
    border-left: 5px solid var(--primary-color);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
}

.consultation-list li:hover {
    background-color: #f0f9f7;
}

.consultation-list li strong {
    color: var(--primary-color);
}

.consultations .cta-button {
    display: block;
    width: max-content;
    margin: 0 auto;
}

/* Contact Section */
.contact {
    padding: 60px 20px;
}

.contact h2 {
    font-family: var(--font-primary);
    font-size: 2.5rem;
    color: var(--dark-text);
    text-align: center;
    margin-bottom: 20px;
}

.contact p {
    font-size: 1.1rem;
    color: var(--light-text);
    text-align: center;
    margin-bottom: 10px;
}

/* Footer Section */
footer {
    background-color: #333;
    color: #fff;
    padding: 30px 20px;
    text-align: center;
}

footer a {
    color: var(--secondary-color);
    text-decoration: underline;
}

footer a:hover {
    color: var(--accent-color);
}

/* Responsive Design */
@media (max-width: 768px) {
    .hero {
        padding: 60px 20px;
    }

    .hero h1 {
        font-size: 2.5rem;
        margin-top: -22px;

    }

    .hero-content p {
        margin-bottom: 0px;
    }

    .hero p {
        font-size: 1rem;
    }

    .cta-button {
        padding: 10px 20px;
        font-size: 1rem;
    }

    .service-cards {
        grid-template-columns: 1fr;
    }

    .consultation-list li {
        padding: 15px;
    }
}