.footer {
    background-color: #e8e8e8;
    color: #242833;
    padding: 40px 20px;
    font-family: "Lora", serif;
    box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1);
}

.footer-top {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 30px;
}

.footer-column {
    flex: 1;
    min-width: 220px;
    margin-bottom: 20px;
    padding: 10px 13px;
}

.footer-column h2 {
    font-size: 18px;
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    margin-bottom: 10px;
}

.footer-column ul {
    list-style: none;
    padding: 0;
}

.footer-column ul li {
    margin-bottom: 10px;
}

.footer-column ul li a {
    color: #242833;
    text-decoration: none;
}

.footer-column ul li a:hover {
    text-decoration: underline;
}

.social-links {
    display: flex;
    gap: 10px;
    justify-content: center;
}

.social-links a {
    display: inline-block;
    width: 30px;
    height: 30px;
    background-size: contain;
}

.newsletter-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.newsletter-form input {
    padding: 10px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
}

.newsletter-form button {
    padding: 10px;
    font-size: 16px;
    background-color: #A8D5BA;
    color: #333;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.newsletter-form button:hover {
    background-color: #94c7a5;
}

.footer-address {
    padding: 20px 0;
    background-color: #ede3e3;
    text-align: center;
    margin-bottom: 30px;
}

.footer-address h3 {
    font-family: "Roboto", sans-serif;
    color: #1a1a1a;
    font-weight: 600;
    margin-bottom: 8px;
}

.footer-address p {
    font-size: 14px;
    color: #555;
    line-height: 1.5;
}

.footer-bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 15px 0;
    background-color: #ede3e3;
}

.payment-methods ul {
    list-style: none;
    display: flex;
    gap: 20px;
    padding: 0;
}

.payment-methods ul li {
    margin-bottom: 0;
}

.payment-methods ul li img {
    width: 40px;
    height: auto;
}

.footer-info {
    text-align: right;
    font-size: 14px;
}

.footer-info a {
    color: #242833;
    text-decoration: none;
}

.footer-info a:hover {
    text-decoration: underline;
}

@media (max-width: 768px) {
    .footer-top {
        flex-direction: column;
    }

    .footer-bottom {
        flex-direction: column;
        text-align: center;
        gap: 20px;
    }
}

.icon-facebook {
    background-image: url('/src/assets/icons/facebook.svg');
}

.icon-instagram {
    background-image: url('/src/assets//icons/instagram.svg');
}

.icon-youtube {
    background-image: url('/src/assets//icons/youtube.svg');
}

.icon-patreon {
    background-image: url('/src/assets//icons/patreon.svg');
}