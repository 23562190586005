/* Importing fonts used for a consistent look */
@import url('https://fonts.googleapis.com/css2?family=Lobster&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

body {
    margin: 0;
    padding: 0;
    background-color: #f9f9f9;
}

/* Main container styling */
.about-us {
    padding: 0 20px;
}

.about-us p{
    font-family: "Open Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
}

.aco{
    color: white;
}

/* Hero section styling */
.hero {
    background-color: #4CAF50;
    color: white;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 20px;
}

.hero-content {
    max-width: 800px;
}

.hero h1 {
    font-family: "Lobster", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 3rem;
    margin: 0;
}

.tagline {
    font-size: 1.5rem;
    font-style: italic;
    margin-top: 10px;
}

/* Content section styling */
.content {
    max-width: 1200px;
    margin: 40px auto;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

/* Card styling */
.card {
    background: white;
    padding: 40px;
    border-radius: 12px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
}

.card h2 {
    font-family: "Lobster", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 2rem;
    color: #27ae60;
    margin-bottom: 20px;
}

.card p {
    font-family: "Open Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-size: 1.1rem;
    line-height: 1.6;
    color: #555;
}

/* Contact button styling */
.contact-button {
    display: inline-block;
    margin-top: 20px;
    padding: 12px 24px;
    background-color: #4CAF50;
    color: white;
    font-size: 1rem;
    font-family: 'Roboto', sans-serif;
    border-radius: 25px;
    text-decoration: none;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.contact-button:hover {
    background-color: #45a049;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}


/* Mobile Responsiveness */
@media (max-width: 768px) {
    .hero {
        height: 300px;
    }

    .hero h1 {
        font-size: 2.5rem;
    }

    .tagline {
        font-size: 1.2rem;
    }

    .card {
        padding: 20px;
    }

    .card h2 {
        font-size: 1.5rem;
    }

    .card p {
        font-size: 1rem;
    }
}
