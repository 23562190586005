@import url('https://fonts.googleapis.com/css2?family=Lobster&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

.site-header {
    background-color: #A8D5BA;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    position: sticky;
    top: 0;
    z-index: 1000;
    width: 100%;
}

.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    max-width: 1200px;
    margin: 0 auto;
}

.logo-img {
    width: 102px;
    height: auto;
}

.main-nav {
    display: flex;
    align-items: center;
    font-family: "Open Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    flex-grow: 1;
    justify-content: center;
}

.main-nav ul {
    display: flex;
    list-style: none;
    gap: 30px;
    font-family: "Lora", serif;
    margin: 0;
    align-items: center;
    padding: 0;
}

.main-nav a {
    text-decoration: none;
    color: #004F6D;
    font-weight: 600;
    font-family: "Lora", serif;
    font-size: 16px;
}

.appointment-button {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    text-decoration: none !important;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.appointment-button a{
    text-decoration: none !important;
    color: white;
}

.mobile-appointment-button{
    display: none;
}

.appointment-button:hover {
    background-color: #45a049;
}

.hamburger {
    display: none;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
}

@media (max-width: 1024px) {
    .header-container {
        padding: 10px 43px;
    }

    .main-nav ul {
        gap: 20px;
    }

    .logo-img {
        width: 88px;
    }
}

@media (max-width: 768px) {
    .main-nav {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        background-color: #fff;
        display: flex;
        height: 100vh;
        flex-direction: column;
        align-items: center;
        transform: translateY(-100%);
        transition: transform 0.3s ease-in-out;
        padding: 20px 0;
        opacity: 0;
        visibility: hidden;
    }

    .main-nav.open {
        transform: translateY(0);
        opacity: 1;
        visibility: visible;
    }

    .main-nav ul {
        flex-direction: column;
        margin-top: -392px;
        gap: 45px;
        justify-content: center;
        align-items: center;
        }

    .appointment-button {
        display: none;
    }

    .hamburger {
        display: block;
    }

    .mobile-appointment-button {
        display: block;
    }

    .main-nav .mobile-appointment-button {
        display: block;
        width: 100%;
        text-align: center;
        margin-top: 10px;
    }

    .main-nav .mobile-appointment-button a {
        display: inline-block;
        background-color: #4CAF50;
        color: white;
        padding: 10px 20px;
        border-radius: 4px;
        text-decoration: none;
    }

    .main-nav .mobile-appointment-button a:hover {
        background-color: #45a049;
    }
}

@media (max-width: 480px) {
    .header-container {
        padding: 10px;
    }

    .logo-img {
        width: 100px;
    }

    .main-nav a {
        font-size: 14px;
    }
}
