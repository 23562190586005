.stories-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  background-color: #f7f7f7;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.stories-header {
  text-align: center;
  margin-bottom: 40px;
  border-bottom: 2px solid #eee;
  padding-bottom: 20px;
}

.stories-header p{
  margin-bottom: 20px;
}

.stories-header-image {
  width: 100%;
  height: 506px;
  margin-bottom: 20px;
}

.stories-content {
  margin-bottom: 40px;
}

.stories-content h2{
  margin-bottom: 25px;

}

.story-thumbnails {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.thumbnail-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.thumbnail-wrapper img {
  width: 100%;
  max-width: 400px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.thumbnail-wrapper img:hover {
  transform: scale(1.05);
}

.thumbnail-wrapper p {
  margin-top: 10px;
  font-size: 1rem;
  color: #2c3e50;
}

.social-media-links ul {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
  gap: 30px;
  flex-wrap: wrap; /* Allows the links to wrap on smaller screens */
}

.social-media-links li {
  margin: 10px 0; /* Adds spacing between links on mobile */
}

.social-media-links li a {
  color: #27ae60;
  font-size: 1.2rem;
  text-decoration: none;
  transition: color 0.3s ease;
}

.social-media-links li a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .social-media-links ul {
    gap: 15px; /* Reduces gap between links for smaller screens */
  }

  .social-media-links li a {
    font-size: 1rem; /* Adjust font size for better visibility on mobile */
  }
  
  .story-thumbnails {
    grid-template-columns: 1fr;
  }

  .stories-header-image{
    height: auto;
  }

  .thumbnail-wrapper img {
    width: 100%;
    height: auto;
  }
}

/* Latest or Live Video Section */
.latest-video-section {
  margin-bottom: 40px;
  text-align: center;
}

.video-wrapper {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
}

.latest-video-section p {
  margin-top: 10px;
  font-size: 1rem;
  color: #2c3e50;
}
