@import url('https://fonts.googleapis.com/css2?family=Lobster&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

body {
    margin: 0;
    padding: 0;
    background-color: #f4f7fb;
}

.contact-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
    background: linear-gradient(to bottom right, #e0f7fa, #f4f7fb);
    padding-bottom: 50px;
}

.contact-header-section {
    text-align: center;
    padding: 80px 20px;
    background-color: #4CAF50;
    color: white;
    clip-path: polygon(0 0, 100% 0, 100% 80%, 0 100%);
}

.contact-heading {
    font-family: "Lobster", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 2.8rem;
    color: white;
    font-weight: 500;
    margin-bottom: 10px;
}

.contact-subheading {
    font-family: "Open Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-size: 1.3rem;
    margin-top: 10px;
    color: #e0f7fa;
}

.contact-container {
    display: flex;
    justify-content: center;
    gap: 40px;
    margin: 50px 20px;
    flex-wrap: wrap;
}

.address-card {
    background-color: white;
    padding: 40px;
    width: 100%;
    max-width: 500px;
    box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    text-align: left;
    line-height: 1.6;
    animation: floating 3s ease-in-out infinite;
}

.address-card:hover {
    transform: translateY(-10px);
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.15);
}
.address-card p{
    font-family: "Open Sans", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
}

@keyframes floating {
    0% {
        transform: translateY(0px);
    }
    50% {
        transform: translateY(-8px);
    }
    100% {
        transform: translateY(0px);
    }
}

.address-title {
    font-family: "Open Sans", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    font-size: 1.7rem;
    color: #004f6d;
    margin-bottom: 15px;
    font-weight: 600;
}

.query-section {
    text-align: center;
    margin-top: 40px;
}

.query-text {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 10px;
    color: #004f6d;
    font-family: "Open Sans", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
}

.email-button {
    display: inline-block;
    background-color: #4CAF50;
    color: #ffffff;
    padding: 14px 28px;
    border-radius: 25px;
    text-decoration: none;
    font-size: 1.1rem;
    font-family: "Open Sans", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    font-weight: 500;
    transition: background-color 0.3s ease, transform 0.3s ease;
    margin-top: 0px;
    margin-bottom: 30px;
    animation: floating 3s ease-in-out infinite;
}

.email-button:hover {
    background-color: #45a049;
    transform: translateY(-3px);
}

@media (max-width: 768px) {
    .contact-header-section {
        padding: 60px 20px;
    }

    .contact-heading {
        font-size: 2.2rem;
    }

    .contact-subheading {
        font-size: 1.1rem;
        margin-bottom: 30px;
    }

    .address-card {
        padding: 30px;
        max-width: 100%;
    }

    .query-text {
        font-size: 1.1rem;
    }

    .email-button {
        padding: 12px 24px;
    }
}
